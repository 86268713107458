.checkHome {
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.04);
}
.buttonPrev {
  top: 50%;
  position: absolute;
  left: 0;
  z-index: 1;
}

.buttonNext {
  top: 50%;
  position: absolute;
  z-index: 1;
  right: 0;
}
.paddingMd {
  padding: 0px 5%;
}
.shadowed {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  z-index: 500;
}
.bgSection3 {
  background: linear-gradient(180deg, #2696dd 0%, rgba(38, 115, 221, 0.9) 100%);
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.04);
}
